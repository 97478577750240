.wrap-content {
	padding: 5px 0 20px;
}

.title {
	margin: 30px 0 30px;
	color: $accent;
	font-size: 30px;
	font-weight: 900;
	line-height: 1;
	@include respond-to('medium') {
		margin: 20px 0;
	}
	@include respond-to('small') {
		font-size: 25px;
	}
}

.content-right-column {
	border-#{$left}: 1px solid #c6c6c6;
	padding-#{$left}: 40px;
	@include respond-to('medium') {
		padding-#{$left}: 0px;
		border-#{$left}: none;
	}
	// & ul {
	// 	list-style: none;
	// 	margin-#{$right}: 15px;
	// 	padding-#{$right}: 0;
	// 	margin-bottom: 45px;
	// }
	// & li {
	// 	font-size: 20px;
	// 	font-weight: 400;
	// 	color: #000;
	// 	line-height: 1.3;
	// 	margin-bottom: 5px;
	// 	@include respond-to('small') {
	// 		font-size: 18px;
	// 		font-weight: 300;
	// 	}
	// 	&::before {
	// 		background-color: $accent;
	// 		content: "";
	// 		display: inline-block;
	// 		height: 5px;
	// 		width: 5px;
	// 		margin-#{$left}: 15px;
	// 		vertical-align: middle;
	// 	}
	// }
}

.content-left-column {
	float: $left;
	@include respond-to('medium') {
		display: none;
	}
}

.content-form {
	position: relative;
	float: $left;
	max-width: 300px;
	width: 100%;
}

.content-form-title {
	margin-bottom: 30px;
	color: $accent;
	font-size: 26px;
	font-weight: 300;
	line-height: 1.3;
}

.form-position {
	position: relative;
	padding: 30px 45px;
	box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
	background-color: #fff;
}

.field {
	max-width: 205px;
	width: 100%;
	height: 50px;
	font-size: 18px;
	line-height: 1.3;
	font-weight: 300;
	border: none;
	border-bottom: 1px solid #c6c6c6;
	&:focus::-webkit-input-placeholder {
		opacity: 0;
		transition: 0.3s;
	}
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:focus {
		box-shadow: 0 0 0px 1000px #fff inset;
	}
	&__area {
		height: 130px;
		margin-bottom: 30px;
		resize: none;
		padding-top: 12px;
	}
}

.form-button {
	max-width: 205px;
	width: 100%;
	height: 40px;
	background-color: #00a5ac;
	color: #fff;
	font-size: 18px;
	font-weight: 300;
	line-height: 1.3;
	border: none;
	&:active {
		@include btn-effect-active;
	}
}

.hr-line {
	margin-top: 0;
	margin-bottom: 0;
	color: #c6c6c6;
}

.content-text {
	h2,
	h3 {
		font-size: 25px;
		font-weight: 400;
		line-height: 1.2;
		color: $accent;
		margin: 15px 0 15px;
		@include respond-to('small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 300;
		color: #000;
		line-height: 1.2;
		@include respond-to('medium') {
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			text-align: justify;
			font-size: 16px;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}

.ribbon {
	pointer-events: none;
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 113px;
	height: 113px;
	text-align: $right;
  transition: all 0.3s ease;
  @include if-ltr(){
    left: auto;
    right: -5px;
    transform: rotate(90deg);
  }
	& span {
		font-size: 16px;
		font-weight: 400;
		color: #FFF;
		text-transform: uppercase;
		text-align: center;
		line-height: 30px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 150px;
		display: block;
		background: #00a5ac;
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 28px;
		left: -32px;
		&::before {
			content: "";
			position: absolute;
			left: 0px;
			top: 100%;
			z-index: -1;
			border-left: 3px solid #013a84;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #013a84;
		}
		&::after {
			content: "";
			position: absolute;
			right: 0px;
			top: 100%;
			z-index: -1;
			border-left: 3px solid transparent;
			border-right: 3px solid #013a84;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #013a84;
		}
	}
	&_accent {
		width: 130px;
		height: 130px;
		z-index: 11;
		& span {
			width: 170px;
			top: 35px;
			left: -35px;
			background: $accent;
			font-size: 14px;
			&::before {
				border-left-color: #3e0645;
				border-top-color: #3e0645;
			}
			&::after {
				border-right-color: #3e0645;
				border-top-color: #3e0645;
			}
		}
	}
}