.slide-buttons {
	@include respond-to('extra-small') {
		& form {
			text-align: center;
		}
	}
}

.slide-dropdown {
	display: inline-block;
	margin-#{$left}: 22px;
	position: relative;
	top: -1px;
	@include respond-to('extra-small') {
		margin-#{$left}: 0px;
	}
	& span.select2.select2-container.select2-container--default {
		width: 260px !important;
	}
	& .select2-selection.select2-selection--single {
		border: none !important;
		&:hover,
		&:focus {
			outline: 1px solid $accent !important;
		}
	}
	& .select2-selection.select2-selection--single {
		height: 50px !important;
		background-color: rgba(240, 243, 245, 0.9) !important;
	}
	& .select2-selection__rendered {
		font-size: 20px !important;
		line-height: 50px !important;
		font-weight: 300 !important;
		color: $accent !important;
		padding-#{$right}: 20px !important;
		padding-#{$left}: 35px !important;
	}
	& .select2-selection__clear {
		float: #{$right} !important;
		margin-#{$left}: 5px !important;
	}
	& .select2-selection__arrow {
		#{$left}: 15px !important;
		top: 10px !important;
		& b {
			height: 0 !important;
			left: 43% !important;
			margin-left: -4px !important;
			margin-top: -2px !important;
			position: absolute !important;
			top: 37% !important;
			border: solid #000 !important;
			border-width: 0 1px 1px 0 !important;
			display: inline-block !important;
			padding: 5px !important;
			transform: rotate(45deg);
		}
	}
	& .select2-selection__placeholder {
		color: #000 !important;
	}
}

.select2-dropdown {
	background-color: rgba(240, 243, 245, 0.9) !important;
}

.slide-find {
	display: inline-block;
	max-width: 360px;
	width: 100%;
	height: 50px;
	@include respond-to('extra-small') {
		max-width: none;
	}
	input::-webkit-input-placeholder {
		color: $accent;
		opacity: 1;
		transition: opacity 0.3s ease;
	}
	input:focus::-webkit-input-placeholder {
		color: $accent;
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	@include respond-to('medium') {
		display: block;
		margin-top: 15px;
		margin-bottom: 15px;
	}
	@include respond-to('small') {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.placeholder {
	color: $accent;
	// opacity: 0;
	// transition: opacity 0.3s ease;
}

.field-find {
	height: 50px;
	max-width: 360px;
	width: 100%;
	background-color: rgba(240, 243, 245, 0.9);
	font-size: 20px;
	line-height: 1;
	font-weight: 300;
	color: #000;
	padding-#{$right}: 20px;
	border: none;

	@include respond-to('extra-small') {
		font-size: 16px;
		max-width: 260px;
	}
	&:hover {
		outline: 1px solid $accent;
	}
}

.banner-button {
	display: inline-block;
}

.button-main {
	//height: 50px;
	color: #fff;
	font-size: 20px;
	font-weight: 300;
	&__slide {
		width: 240px;
		margin-#{$right}: 22px;
		background-color: rgba($color: $accent, $alpha: 0.9);
		padding: 15px 20px;
		line-height: 1.1;
		text-align: center;
		border: none;
		@include respond-to('medium') {
			margin-#{$right}: 0;
			width: 260px;
		}
	}
	&:hover {
		outline: 1px solid #fff;
	}
}

.arrow {
	border: solid #fff;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 3px;
	margin-bottom: 2px; // &_banner {
	//   float: $left;
	//   margin-top: 7px;
	// }
	&_footer {
		border-color: #2cb0db;
	}
}

.left {
	transform: rotate(if-rtl((135deg), (-45deg)));
}