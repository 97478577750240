.sales-box {
	position: relative;
	overflow: hidden;
	display: block;
	width: 100%;
	height: 300px;
	@include respond-to('extra-small') {
		height: 250px;
	}
	&:hover {
		& .sales-box__image {
			transform: scale(1.05);
			&::after {
				background-color: rgba($color: #000000, $alpha: 0);
			}
		}
	}
	&__image {
		position: relative;
		width: 100%;
		height: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		transition: transform 0.3s ease;
		@include respond-to('small') {
			text-align: center;
		}
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.2);
			transition: background 0.3s ease;
		}
	}
	&__text {
		max-width: 390px;
		width: 87%;
		position: absolute;
		bottom: 6.5%;
		#{$right}: 30px;
		color: #fff;
		opacity: 1;
		visibility: visible;
		@include respond-to('small') {
			width: 70%;
			left: 50%;
			right: auto;
			transform: translateX(-50%);
		}
		@include respond-to('extra-small') {
			width: 90%;
		}
		&_title {
			h2 {
				margin-top: 0;
				font-size: 30px;
				font-weight: 900;
				margin-bottom: 7px;
				line-height: 1.3;
				text-align: $right;
			}
		}
		&_description {
			border-top: 1px solid rgba(255, 255, 255, 0.3);
			font-size: 20px;
			font-weight: 300;
			padding-top: 15px;
			line-height: 1.3;
			text-align: $right;
		}
	}
	@include on-event(false) {
		img {
			transform: scale(1.1);
		}
	}
	&__shadow {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.6);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease;
	}
	&__shadow-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 30px;
		font-weight: 900;
		color: #fff;
		text-align: center;
	}
}
