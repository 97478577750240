.hotels-catalog-items-favorite {
	position: relative;
	min-height: 120px;
	z-index: 2;
	transition: all 1s ease;
	&__item {
		float: $right;
		background-color: #fff;
		width: 50%;
		transition: all 1s ease;
		@include respond-to('small') {
			width: 100%;
		}
	}
	& .catalog-item-wrap {
		position: relative;
		opacity: 1;
		&.close-item {
			opacity: 0;
			transform: scale(0.8);
			transition: all 1s ease;
		}
		& .add-to-favorite {
			display: none;
		}
		& .del-from-favorite {
			display: block;
		}
	}
}

.favorite-text-help {
	opacity: 0;
	visibility: hidden;
	transition: all 1s ease 0.5s;
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 480px;
	width: 100%;
	color: #c6c6c6;
	font-size: 30px;
	font-weight: 700;
	text-align: center;
	z-index: 1;
	@include respond-to('medium') {
		top: 50%;
	}
	@include respond-to('extra-small') {
		font-size: 25px;
	}
	&_show {
		opacity: 1;
		visibility: visible;
	}
}