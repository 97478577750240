.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 500px;
	// @include respond-to('small') {
	// 	min-height: inherit;
	// }
}

.banner-top-content__image {
	background-position: center center;
	background-size: cover;
	height: 500px;
	box-shadow: inset 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
}

.banner-shadow {
	position: absolute;  
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #000000, $alpha: 0.5);
}

.banner-text {
	position: absolute;
	top: 52%;
	left: 50%;
	max-width: 900px;
	width: 100%;
	padding: 0 15px;
	color: #fff;
	transform: translate(-50%, 0);
	text-align: center;
	&_subtitle {
		font-size: 30px;
		font-weight: 500;
		line-height: 1;
		@include respond-to('small') {
			font-size: 25px;
		}
		@include respond-to('mobile-l') {
			font-size: 22px;
		}
	}
	&_title {
		font-size: 56px;
		font-weight: 900;
		line-height: 1;
		@include respond-to('medium') {
			font-size: 46px;
			font-weight: 700;
		}
		@include respond-to('small') {
			font-size: 35px;
			margin-top: 10px;
		}
		@include respond-to('mobile-l') {
			font-size: 30px;
		}
	}
}
