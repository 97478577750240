.wrapper-box-image{
  position:relative;
  overflow: hidden;
  margin:0 auto;
  text-align:center;
  margin-bottom: 30px;
  max-height: 190px;
  @include respond-to('small'){
    height: auto;
  }
  &:hover{
    cursor: pointer;
   & .box-image{
      & > div > a{
        & > img{
          transform: scale(1.08);
        }
      }
      & .box-image__icon-zoom,
      &:after{
        opacity: 1;
      }
    }
  }
}

.wrapper-box-image__title{
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 5px;
}

.main-gallery{
  & a{
    &:hover{
      text-decoration: none;
    }
  }
}

.box-image{
 position: relative;
 overflow: hidden;
 &__icon-zoom{
   color: $accent;
   opacity: 0;
   z-index: 2;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   @include transition(opacity .4s ease);
   pointer-events: none; 
 }
 &:after{
   content: "";
   position: absolute;
   width: 80%;
   height: 80%;
   top: 50%;
   left: 50%;
   transform: translate(-50% ,-50%);
   background: rgba(255,255,255,0.75);
   box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.5);
   pointer-events: none;
   @include transition(opacity .5s ease);
   opacity: 0;
 }
 & > div > a{
   & > img{
     @include transition(all .7s ease);
     height: 100%;
   }
 }
}
