.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
}

.top-boxes-section {
	margin: 0 -15px;
	padding-top: 40px;
	padding-bottom: 28px;
	@include respond-to('huge') {
		padding-top: 24px;
		padding-bottom: 12px;
	}
	@include respond-to('medium') {
		padding-bottom: 0;
	}
}

.top-boxes {
	position: relative;
	float: $right;
	width: 100%;
	padding: 0 12px;
	&::after {
		display: none;
		content: '';
		position: absolute;
		z-index: auto;
		top: 0;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: calc(100% - 24px);
		height: 100%;
		background-color: rgba($color: #00a5ac, $alpha: 0.5);
		@include respond-to('medium') {
			display: block;
		}
	}
	&_two,
	&_three {
		& .top-box {
			padding: 25px;
			@include respond-to('medium') {
				padding: 15px;
			}
			&__title {
				font-size: 30px;
				@include respond-to('medium') {
					font-size: 25px;
				}
			}
			&__text {
				@include respond-to('medium') {
					margin-top: 15px;
				}
			}
			&__btn {
				margin-top: 20px;
				@include respond-to('medium') {
					margin-top: 15px;
				}
			}
		}
	}
	&_two {
		width: 50%;
		@include respond-to('large') {
			&::after {
				display: block;
			}
		}
		@include respond-to('small') {
			width: 100%;
			margin-bottom: 12px;
		}
	}
	&_three {
		width: 33.33%;
		@include respond-to('huge') {
			&::after {
				display: block;
			}
		}
		@include respond-to('1100') {
			width: 100%;
			margin-bottom: 12px;
		}
	}
}

.top-box {
	width: 100%;
	padding: 50px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-origin: padding-box;
	@include respond-to('medium') {
		padding: 25px;
	}
	&__title {
		position: relative;
		z-index: 1;
		max-width: 570px;
		font-size: 40px;
		font-weight: 700;
		line-height: 1.2;
		color: #fff;
		@include respond-to('medium') {
			max-width: 480px;
			font-size: 35px;
		}
	}
	&__text {
		position: relative;
		z-index: 1;
		max-width: 570px;
		margin-top: 20px;
		line-height: 1.3;
		font-size: 18px;
		font-weight: 300;
		color: #fff;
		@include respond-to('medium') {
			max-width: 480px;
		}
	}
	&__btn {
		position: relative;
		z-index: 1;
		display: inline-block;
		margin-top: 50px;
		max-width: 140px;
		width: 100%;
		padding: 5px 5px;
		background-color: $accent;
		text-align: center;
		font-size: 18px;
		font-weight: 300;
		line-height: 1.4;
		@include link_no-hover(#fff);
		&:active {
			@include btn-effect-active;
		}
		@include respond-to('medium') {
			margin-top: 25px;
		}
	}
}

.homepage__buttons {
	padding: 12px 0 0;
}

.hp-butt-wrap {
	padding: 0 12px;
	float: $right;
	width: 33.33%;
	margin-bottom: 24px;
	@include respond-to('medium') {
		width: 50%;
	}
	@include respond-to('small') {
		float: none;
		max-width: 450px;
		margin: 0 auto 24px;
		width: 100%;
	}
	@include respond-to('extra-small') {
		margin-bottom: 15px;
	}
}

.hp-hr {
	margin: 15px 0 0;
	border-top: 1px solid #b7b7b7;
	@include respond-to('medium') {
		margin: 0;
	}
}

.hp-sales {
	&__title {
		margin: 30px 0;
		line-height: 1;
		font-weight: 700;
		font-size: 30px;
		color: #000;
		text-align: center;
		@include respond-to('medium') {
			font-size: 25px;
			margin: 20px 0 12px;
		}
	}
	&__slider {
		margin: 0 -8px;
		padding-bottom: 80px;
		@include respond-to('medium') {
			margin: 0;
			padding-bottom: 50px;
		}
		@include respond-to('small') {
			max-width: 560px;
			margin: 0 auto;
		}
		@include respond-to('extra-small') {
			max-width: 560px;
			margin: 0 auto;
		}
		& .slick-dots {
			position: absolute;
			bottom: 30px;
			#{$left}: 0;
			text-align: center;
			@include respond-to('medium') {
				bottom: 15px;
			}
			@include respond-to('mobile-l') {
				bottom: 10px;
			}
			& li {
				margin-#{$left}: 0;
				& button {
					height: 5px;
					width: 14px;
					padding: 0;
					&::before {
						opacity: 1;
						content: '';
						background-color: #d0d0d0;
						height: 5px;
						width: 14px;
						@include respond-to('mobile-l') {
							height: 3px;
							width: 10px;
						}
					}
				}
				&.slick-active {
					& button {
						&::before {
							background-color: $accent;
						}
					}
				}
			}
		}
	}
	&__slide {
		max-width: 474px;
		width: 100%;
		max-height: 324px;
		padding: 12px;
		display: none;
		&:first-child {
			display: block;
		}
		@include respond-to('medium') {
			max-width: 490px;
		}
		@include respond-to('small') {
			margin: 0 auto;
		}
		@include respond-to('extra-small') {
			width: 320px;
			padding: 12px 2px;
		}
		@include respond-to('mobile-l') {
			max-height: 250px;
		}
	}
}