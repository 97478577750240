.hotels-catalog-title {
	margin: 30px 0;
	@include respond-to('small') {
		margin: 15px 0;
	}
}

.hotels-quantity {
	font-size: 30px;
	font-weight: 300;
	color: #777777;
	@include respond-to('extra-small') {
		text-align: center;
		margin-bottom: 10px;
		font-size: 24px;
	}
}

.quantity {
	color: #00a5ac;
}

.hotels-catalog-items {
	padding-bottom: 30px;
	@include respond-to('medium') {
		padding-bottom: 15px;
	}
	&_favorite {
		position: relative;
	}
}
