.homepage-box {
	position: relative;
	display: block;
	height: 300px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include respond-to('extra-small') {
		height: 250px;
	}
	&:hover {
		& .homepage-box__shadow {
			opacity: 1;
			visibility: visible;
		}
		& .homepage-box__text {
			opacity: 0;
			visibility: hidden;
		}
	}
	&__image {
		position: relative;
		overflow: hidden;
		height: 100%;
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.2);
		}
		@include respond-to('small') {
			text-align: center;
		}
		// img {
		// 	height: 100%;
		// 	max-width: 100%;
		// 	width: 100%;
		// 	transition: transform 300ms;
		// }
	}
	&__text {
		max-width: 390px;
		width: 87%;
		position: absolute;
		bottom: 15px;
		#{$right}: 30px;
		color: #fff;
		opacity: 1;
		visibility: visible;
		z-index: 1;
		@include respond-to('1100') {
			#{$right}: 0px;
			width: 100%;
			padding: 0 15px;
		}
		&_title {
			h2 {
				margin-top: 0;
				font-size: 30px;
				font-weight: 900;
				margin-bottom: 7px;
				line-height: 1.3;
				text-align: $right;
				@include respond-to('1100') {
					font-size: 25px;
					line-height: 1;
				}
			}
		}
		&_description {
			border-top: 1px solid rgba(255, 255, 255, 0.3);
			font-size: 20px;
			font-weight: 300;
			padding-top: 15px;
			line-height: 1.3;
			text-align: $right;
			@include respond-to('1100') {
				padding-top: 10px;
				line-height: 1.1;
			}
		}
	}
	// @include on-event(false) {
	// 	img {
	// 		transform: scale(1.1);
	// 	}
	// }
	&__shadow {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: #000000, $alpha: 0.6);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease;
	}
	&__shadow-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 30px;
		font-weight: 900;
		color: #fff;
    text-align: center;
    & a{
      color: #fff;
      text-decoration: none;
      &:hover{
        color: #fff;
        text-decoration: underline;
      }
    }
		@include respond-to('1100') {
			font-size: 25px;
			font-weight: 700;
			line-height: 1.2;
		}
	}
}