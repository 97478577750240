.wrapper-banners {
	position: relative;
	width: 100%;
	height: 500px;
	overflow: hidden;
	@include respond-to('large') {
		// height: 480px;
	}
	@include respond-to('extra-small') {
		// height: 300px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 500px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.wrapper-banner-form {}

.banner-form-wrap {
	position: absolute;
	top: 59%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 920px;
	width: 100%;
	@include respond-to('medium') {
		padding: 0 15px;
	}
	@include respond-to('extra-small') {
		top: 55%;
	}
}

.slide-text {
	margin-bottom: 16px;
	color: #fff;
	text-shadow: 0 0 25px rgba($color: #000, $alpha: 1);
	@include respond-to('medium') {}
	@include respond-to('extra-small') {}
	& .subtitle-text {
		font-size: 30px;
		font-weight: 400;
		line-height: 1;
		@include respond-to('small') {
			font-size: 25px;
			line-height: 1.3;
		}
		@include respond-to('extra-small') {
			text-align: center;
		}
		@include respond-to('mobile-l') {
			font-size: 22px;
		}
	}
	& .title-text {
		font-size: 56px;
		font-weight: 800;
		line-height: 1;
		@include respond-to('medium') {
			font-size: 46px;
			font-weight: 600;
		}
		@include respond-to('small') {
			font-size: 35px;
		}
		@include respond-to('extra-small') {
			font-size: 36px;
			text-align: center;
		}
		@include respond-to('mobile-l') {
			font-size: 30px;
		}
	}
}