.sales-block {
	margin: 0 -15px;
	& .hp-sales__slide {
		display: block;
		float: $right;
		// height: 300px;
		max-height: none;
		width: 33.33%;
		max-width: unset;
		padding: 15px;
		@include respond-to('medium') {
			width: 50%;
		}
		@include respond-to('small') {
			width: 100%;
			max-width: 450px;
			margin: 0 auto;
			float: none;
			padding: 7px 15px;
		}
	}
}