.wrapper-popup-search {}

.btn-close-popup {
	color: #777;
	margin: 20px;
	cursor: pointer;
}

.modal-backdrop {
	&.in {
		opacity: 0.7;
	}
}

.main-popup-search {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	&_width-limit {
		max-width: 1000px;
		width: 100%;
		margin: 0 auto;
	}
}

.popup-search-form {
	@include respond-to('small') {
		padding: 0 10px;
	}
	&_relative {
		position: relative;
	}
	&__input {
		width: 100%;
		border: 1px solid $accent;
		background-color: #000;
		line-height: 30px;
		font-size: 22px;
		color: #fff;
		padding: side-values(20px 80px 20px 20px);
		border-radius: 5px;
		&::placeholder {
			font-size: 24px;
			color: #777;
			font-weight: 400;
			transition: opacity 0.4s;
		}
		&:focus {
			outline: none;
			&::placeholder {
				//opacity: 0;
			}
		}
	}
	&__btn-search {
		position: absolute;
		top: 0;
		#{$right}: 0;
		width: 70px;
		height: 100%;
		background-color: transparent;
		border: 0;
		border-#{$left}: 1px solid $accent;
		& > i {
			color: $accent;
		}
		&:focus {
			outline: none;
		}
	}
}