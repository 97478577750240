.go-back {
	margin-top: 32px;
	text-align: $left;
	@include respond-to('medium') {
		margin-top: 22px;
	}
	@include respond-to('small') {
		// text-align: center;
		margin-bottom: 10px;
		margin-top: 0;
	}
}

.go-back-link {
	@include link_on-hover(#777777, #013c88);
	&:hover {
		text-decoration: none;
	}
	&__text {
		text-decoration: none;
		color: #777777;
		font-size: 20px;
		font-weight: 300;
		&:hover {
			color: #013c88;
		}
	}
	&__img {
		float: #{$left};
		padding-top: 6px;
		transform: rotateY(if-rtl((0deg), (180deg)));
		@include respond-to('small') {
			float: none;
			padding-top: 0;
			margin-#{$right}: 5px;
		}
		margin-#{$right}: 20px;
	}
}

.hr-line {
	border-top: 1px solid #c6c6c6;
}

.vertical-line {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		width: 1px;
		top: 0;
		background-color: #c6c6c6;
		height: 100%;
		#{$left}: 47%;
		padding: 20px 0;
		@include respond-to('large') {
			#{$left}: 44%;
		}
		@include respond-to('larger') {
			// #{$left}: 50%;
		}
		@include respond-to('large') {
			// #{$left}: 41%;
		}
		@include respond-to('medium') {
			display: none;
		}
	}
}

.hotel-information {
	padding: 40px 0;
	@include respond-to('small') {
		padding: 30px 0;
	}
	@include respond-to('extra-small') {
		padding: 15px 0;
	}
}

.wrap-place-image {
	position: relative;
	display: block;
	width: 100%;
	max-width: 350px;
	height: 250px;
	padding: 30px;
	border: 1px solid #c6c6c6;
	@include respond-to('extra-small') {
		height: 210px;
	}
	&__img {
		position: relative;
		width: 100%;
		height: 100%;
		& img {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			&:hover {
				cursor: zoom-in;
			}
		}
	}
}

.content-form-label {
	top: -6px;
	#{$left}: -4px;
	& .content-form-label__text {
		top: 46px;
		font-size: 14px;
		font-weight: 400;
		#{$left}: -5px;
	}
}

.hotel-main-photo {
	display: block;
	height: 100%;
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.hotel-contact-info {
	& .hotels-catalog-items {
		&__item {
			padding-bottom: 10px;
			padding-#{$left}: 85px;
			box-shadow: 0 0 0 0 transparent;
			@include respond-to('large') {
				padding-#{$left}: 55px;
			}
			@include respond-to('1100') {
				padding-#{$left}: 35px;
			}
			@include respond-to('medium') {
				padding-#{$left}: 0;
				max-width: 700px;
				width: 100%;
				margin: 0 auto 15px;
			}
			@include respond-to('small') {
				max-width: 450px;
				padding-bottom: 0;
			}
			&:hover {
				position: relative;
				z-index: 10;
				border-bottom: none;
				box-shadow: 0 0 0 0 transparent;
			}
			& .wrap-place-image {
				@include respond-to('large') {
					padding: 15px;
				}
				@include respond-to('small') {
					margin: 0 auto;
				}
			}
		}
		&__text {
			padding-#{$right}: 20px;
			@include respond-to('larger') {
				padding-#{$right}: 15px;
			}
			@include respond-to('medium') {
				padding-#{$right}: 30px;
			}
			@include respond-to('small') {
				padding-#{$right}: 0; //text-align: left;
				max-width: 290px;
				margin: 15px auto;
			}
			& i {
				font-size: 23px;
			}
			& .row {
				margin-bottom: 22px;
				@include respond-to('small') {
					margin-bottom: 10px;
				}
			}
			& .icon-desc {
				margin-bottom: 0;
				color: #777777;
				font-size: 20px;
				font-weight: 300;
				line-height: 1.2;
				@include respond-to('mobile-l') {
					font-size: 18px;
				}
				margin-#{$right}: 15px;
				padding-#{$left}: 1px;
				p {
					color: #777777;
					font-size: 20px;
					font-weight: 300;
					@include respond-to('mobile-l') {
						font-size: 18px;
					}
				}
				&_black {
					color: #000000;
				}
			}
		}
	}
}

.icon-desc-link {
	display: block;
	width: 100%;
	max-width: 190px;
	// margin: 0 auto;
	background-color: $accent;
	box-shadow: 0 5px 15px 0 rgba($color: #000000, $alpha: 0.2);
	@include respond-to('small') {
		margin: 0 auto;
	}
	& a {
		display: block;
		width: 100%;
		padding: 6px 10px;
		text-align: center;
		color: #ffffff;
		font-size: 20px;
		font-weight: 600;
		@include link_no-hover(#ffffff);
		&:active {
			@include btn-effect-active;
		}
		& i {
			vertical-align: middle;
			margin-#{$left}: 15px;
		}
	}
}

.hotel-contact-describe {
	& p {
		margin-bottom: 25px;
		text-align: justify;
		font-size: 20px;
		font-weight: 300;
		@include respond-to('small') {
			margin-bottom: 5px;
			font-size: 18px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
		}
	}
}

.hotels-photo {
	padding: 70px 0 30px;
	margin: 0 -3px;
	@include respond-to('medium') {
		padding: 40px 0 20px;
	}
	@include respond-to('small') {
		padding: 25px 0 15px;
	}
	&__item-wrap {
		float: $left;
		width: 20%;
    padding: 3px;
    max-height: 180px;
    overflow: hidden;
		@include respond-to('medium') {
			width: 33.33%;
			padding: 3px;
		}
		@include respond-to('extra-small') {
			width: 50%;
		}
	}
	&__item {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		&:hover {
			cursor: zoom-in;
		}
	}
}

.similiar-variant {
	&__title {
		margin: 0;
		margin-top: 47px;
		margin-bottom: 47px;
		padding: 0;
		color: #777777;
		font-size: 22px;
		font-weight: 300;
		@include respond-to('medium') {
			margin-top: 30px;
			margin-bottom: 30px;
		}
		@include respond-to('small') {
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
}

.hotels-slider {
	& .slick-list {
		border: 1px solid #c6c6c6;
		border-#{$right}: none;
		@include respond-to('small') {
			border: none;
		}
	}
	&__item {
		& .catalog-item-wrap {
			& .hotels-catalog-items-item {
				border-#{$right}: 1px solid #c6c6c6;
				box-shadow: unset;
				@include respond-to('small') {
					border: 1px solid #c6c6c6;
				}
			}
			&:hover {
				& .hotels-catalog-items-item {
					box-shadow: unset;
				}
			}
			& .ribbon {
				display: none;
			}
		}
	}
}

.hotels-similar {
	@include respond-to('small') {
		max-width: 500px;
		margin: 0 auto;
	}
}

.pagination2 {
	margin: 10px auto 40px;
	width: 60px;
	height: 30px;
	text-align: center;
	@include respond-to('medium') {
		margin: 10px auto 30px;
	}
	@include respond-to('small') {
		margin: 10px auto 20px;
	}

	/* Pagination links */

	&__link {
		width: 30px;
		color: #777777;
		float: left;
		font-size: 16px;
		font-weight: 300;
		line-height: 30px;
		text-decoration: none;
		transition: background-color 0.3s;
		border: 1px solid #c6c6c6;
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			& .arrow_pagination2 {
				border-color: #c6c6c6;
			}
		}

		/* Add a grey background color on mouse-over */

		&:hover:not(.pagination2__link_active) {
			background-color: #00a5ac;
			border: 1px solid #00a5ac;
			color: #fff;
		}
		& .arrow {
			padding: 4px;
			margin-bottom: 0;
		}
		& .arrow_pagination2 {
			border-color: #8c8c8c;
		}
		& .right {
			transform: rotate(-45deg);
			margin-right: 4px;
		}
		& .left {
			margin-left: 4px;
			transform: rotate(135deg);
		}
		&_next {
			background-color: #e8e8e8;
		}
	}
	&_bottom {
		display: block;
		float: none;
		margin: 40px auto;
	}
}