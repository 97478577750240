.sales-bnt {
	position: fixed;
	max-width: 170px;
	width: 100%;
	text-align: center;
	z-index: 100;
	left: -64px;
	top: 28%;
	transform: rotate(-90deg);
	padding: 9px 15px;
	background: #00a5ac;
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	box-shadow: 0 0px 10px 0 rgba(0,0,0,.3);
	@include link_no-hover(#fff);
	@include respond-to('medium') {
		top: 65%;
	}
	@include respond-to('extra-small') {
		max-width: 120px;
		padding: 2px 5px;
		font-size: 17px;
		left: -50px;
	}
}