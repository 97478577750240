.catalog-item-wrap {
	position: relative;
	background-color: #fff;
	&:hover {
		& .hotels-catalog-items-item {
			box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.15);
			position: relative;
			background-color: #fff;
			z-index: 11;
			color: #777777;
			&::after {
				bottom: 0;
				opacity: 1;
				visibility: visible;
			}
		}
		& .add-to-favorite {
			z-index: 15;
		}
		& .ribbon_accent {
			// left: -6px;
			// top: -6px;
			z-index: 15;
		}
	}
	@include respond-to('small') {
		margin: 0 auto;
		max-width: 450px;
	}
}

.hotels-catalog-items-item {
	position: relative;
	display: block;
	overflow: hidden;
	width: 100%;
	height: 250px;
	padding: 30px;
	color: #777777;
	box-shadow: 1px 0 0 0 #c6c6c6, 0 1px 0 0 #c6c6c6, 1px 1px 0 0 #c6c6c6, 1px 0 0 0 #c6c6c6 inset, 0 1px 0 0 #c6c6c6 inset;
	transition: all 0.3s ease;
	text-decoration: none;
	@include respond-to('large') {
		margin: 0 auto;
		height: 450px;
	}
	@include respond-to('extra-small') {
		padding: 20px;
		height: auto;
		max-height: 410px;
	}
	@include respond-to('mobile-l') {
		padding: 15px;
		max-height: 385px;
	}
	&::after {
		content: '';
		position: absolute;
		bottom: -3px;
		left: 0;
		right: 0;
		height: 3px;
		width: 100%;
		background: $accent;
		transition: all 0.2s ease;
		opacity: 0;
		visibility: hidden;
		z-index: 10;
	}
}

.hotels-caption {
	&__title {
		max-height: 60px;
		overflow: hidden;
		margin-bottom: 10px;
		font-size: 30px;
		font-weight: 900;
		line-height: 1;
		color: $accent;
    margin-top: 0;
    padding-#{$left}: 40px;
		@include respond-to('large') {
			text-align: center;
			margin-bottom: 5px;
			font-size: 24px;
		}
		@include respond-to('extra-small') {
			margin-bottom: 5px;
			font-size: 19px;
		}
	}
}

.hotels-catalog-items-img {
	height: 190px;
	max-width: 290px;
	overflow: hidden;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	@include respond-to('huge') {
		margin-#{$left}: 10px;
	}
	@include respond-to('large') {
		margin: 0 auto 10px;
	}
	@include respond-to('extra-small') {
		width: 100% !important;
	}
	@include respond-to('mobile-l') {
		height: 170px;
	}
	& img {
		max-width: 100%;
		max-height: 100%;
	}
}

.card-row {
	margin: 0 auto;
	margin-bottom: 10px;
	@include respond-to('large') {
		max-width: 290px;
	}
	@include respond-to('extra-small') {
		margin-bottom: 5px;
	}
	&_col-right {
		display: inline-block;
		width: 30%;
		margin-#{$left}: -4px;
		vertical-align: middle;
		text-align: $right;
	}
	&_col-left {
		display: inline-block;
		width: 70%;
		vertical-align: middle;
	}
}

.hotels-catalog-items-text {
	position: relative;
	height: 190px;
	font-size: 16px;
	font-weight: 300;
	line-height: 1;
	color: #777;
	@include respond-to('large') {
		max-width: 350px;
		margin: 0 auto;
		text-align: center;
	}
	@include respond-to('small') {
		// text-align: center;
	}
	& p {
		margin-bottom: 0;
	}
	& .top-box__btn {
		position: absolute;
		bottom: 0;
		#{$right}: 0;
		@include respond-to('large') {
			#{$right}: auto;
			left: 50%;
			transform: translateX(-50%);
			margin-top: 0;
		}
		@include respond-to('extra-small') {
			bottom: 20px;
		}
		@include respond-to('mobile-l') {
			bottom: 15px;
		}
	}
}

.hotels-catalog-icon {
	margin-#{$left}: 15px;
	color: #02c5cd;
	font-size: 18px;
	@include respond-to('huge') {
		margin-#{$left}: 10px;
	}
	@include respond-to('large') {
		margin-#{$left}: 20px;
	}
	@include respond-to('small') {
		margin-#{$left}: 10px;
	}
}

.icon-descr {
	font-size: 16px;
	font-weight: 300;
	line-height: 1;
	color: #777;
}

.add-to-favorite {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	width: 80px;
	height: 127px;
	background-size: contain;
	background-repeat: no-repeat;
  background-position: top;
  &:checked{
    display: none;
  }
	@include respond-to('extra-small') {
		width: 70px;
		height: 110px;
	}
	& span {
		@include respond-to('extra-small') {
			font-size: 14px;
		}
	}
}

.favorite-text {
	position: relative;
	width: 100%;
  height: 100%;
  cursor: pointer;
	& span {
		position: absolute;
		top: 60%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 16px;
		font-weight: 400;
		color: #fff;
		text-align: center;
		line-height: 1.2;
	}
}

.del-from-favorite {
	display: none;
	position: absolute;
	top: 2px;
	#{$right}: 4px;
	z-index: 15;
	cursor: pointer;
	& i {
		color: #c6c6c6;
		font-size: 30px;
		transition: color 0.2s ease;
		&:hover {
			color: $accent;
		}
	}
}