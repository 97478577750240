.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	background: #fff;
	box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
	// transition: all 0.4s ease;
}

.header-desktop {
	height: 117px;
	transition: height 0.2s ease;
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	float: $left;
	margin-top: 10px;
	width: 96px;
	// transition: all 0.2s ease;
	& img {
		float: left;
		transition: all 0.2s ease;
	}
}

.sub-nav {
	float: $left;
	margin-top: 48px;
	margin-#{$left}: 70px;
	transition: margin-top 0.2s ease;
	& > ul {
		padding: 0;
		margin: 0;
		list-style: none;
		& > li {
			margin: 0 10px;
			display: inline-block;
			vertical-align: middle;
			color: #777777;
			& > a {
				color: #777777;
				& i {
					font-size: 20px;
				}
				& i.fa-instagram {
					font-size: 24px;
					color: #70057b;
				}
				& i.fa-facebook-f {
					font-size: 22px;
					color: #3b5998;
				}
				& i.fa-heart {
					font-size: 22px;
					&_red {
						color: red;
					}
				}
			}
			& i.fa-search {
				font-size: 20px;
			}
		}
		& .select2-container,
		& .select2-selection--single {
			width: 140px !important;
			height: 30px !important;
			@include respond-to('larger') {
				display: none !important;
			}
		}
		& .select2-selection--single,
		& .select2-selection__rendered {
			padding-#{$right}: 15px !important;
			padding-#{$left}: 5px !important;
			line-height: 28px !important;
		}
		& .select2-selection__rendered {
			color: $accent !important;
			& span {
				font-size: 16px;
				font-weight: 400;
				color: #000;
			}
		}
		& .select2-selection__arrow {
			left: 0 !important;
			#{$right}: 5px !important;
			& b {
				height: 0 !important;
				left: 30% !important;
				margin-#{$left}: 0px !important;
				margin-top: -2px !important;
				position: absolute !important;
				top: 30% !important;
				border: solid #c3c2c2 !important;
				border-width: 0 1px 1px 0 !important;
				display: inline-block !important;
				padding: 5px !important;
				transform: rotate(45deg);
			}
		}
		& .select2-search--dropdown.select2-search {
			display: none !important;
		}
	}
}

.lang-change {
	display: none;
	@include respond-to('larger') {
		display: inline-block;
	}
}

.find-last {
	cursor: pointer;
}

.flag {
	width: 20px;
	float: $left;
	margin-top: 6px;
	max-height: 16px;
}

.main-navi {
	display: inline-block;
	margin-top: 48px;
	transition: margin 0.2s ease;
	& > ul {
		display: inline-block;
		list-style: none;
		padding: 0;
		& > li {
			float: $right;
			margin-#{$left}: 5px;
      position: relative;
      padding-bottom: 43px;
			&:first-child {
				& a {
					padding-#{$right}: 0;
				}
			}
			& > a {
				font-size: 20px;
				font-weight: 300;
				color: #777;
				padding: 3px 10px;
				display: block;
				&:hover {
					text-decoration: none;
				}
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					color: $accent;
				}
      }
      &:hover{
        & > ul{
          visibility: visible;
          opacity: 1;
        }
      }
      & > ul{/* level 2 */ 
        display: block;
        padding: 0; 
        margin: 0;
        list-style: none;
        position: absolute;
        top: 70px;
        min-width: 200px;
        #{$right}: 0;
        background-color: #fff;
        transition: all 0.4s;
        visibility: hidden;
        opacity: 0;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5); 
        & > li{
          &:not(:last-child){
            border-bottom: 1px solid #680772;
          }
          & > a{
            font-size: 18px;
            color: #000;
            display: block;
            position: relative;
            white-space: nowrap; 
            padding: 7px 30px;
            transition: all 0.3s;
            font-weight: 300;
            &:hover{
              background-color: #680772;
              text-decoration: none;
              color: #fff;
            }
          }
        }
      }/* END level 2 */
		}
	}
}

.wrap-lang {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-top: 35px;
	// &::before {
	// 	font-family: "Font Awesome 5 Pro";
	// 	font-weight: 300;
	// 	content: "\f107";
	// }
	& > i {
		position: absolute;
		top: 26%;
		#{$right}: 10px;
		pointer-events: none;
		color: #000;
	}
}

.select-lang {
	padding: 5px 33px 6px 10px;
	font-size: 16px;
	color: #000;
	background-color: transparent;
	border: 1px solid #000;
	cursor: pointer;
	border-radius: 3px;
	background-image: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	&:focus {
		outline: none;
	}
	& > option {
		color: #000;
	}
}

.header-mobile {
	display: none;
	height: 70px;
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 7px;
		float: $left;
		& > img {
			width: 54px;
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 19px;
		i {
			color: $accent;
			font-size: 32px;
		}
	}
}

.mobile-menu-btn {
	margin-top: 20px;
}

.main-header.scrolling {

  .main-navi>ul>li>ul{
    top: 56px;
  }
	.header-desktop {
		height: 80px;
	}
	.sub-nav {
		margin-top: 27px;
		// margin-#{$left}: 105px;
	}
	.main-navi {
		margin-top: 25px;
	}
	.logo {
		& img {
			max-width: 58px;
		}
	}
}