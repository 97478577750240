.subscribe-form {
	position: fixed;
	z-index: 100;
	bottom: -392px;
	left: 15%;
	max-width: 340px;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.3);
	transition: bottom 0.2s ease-in-out;
	@include respond-to('small') {
		left: 50%;
		margin-left: -170px;
	}
	@include respond-to('extra-small') {
		width: 100%;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	&_open {
		bottom: 0;
	}
	&__btn {
		padding: 8px 15px;
		background-color: $accent;
		color: #fff;
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		box-shadow: 0 2px 10px 0 rgba($color: #000000, $alpha: 0.3);
		cursor: pointer;
	}
	&__form {
		min-height: 262px;
	}
	&.scrolling2 {
		bottom: -435px;
	}
}

.wrapper-sub-form{
  padding: 22px 25px;
	text-align: center;
}

.sub-form {
	
	&__subtitle {
		color: #000;
		font-size: 20px;
		font-weight: 400;
		line-height: 1.1;
	}
	&__title {
		color: #000;
		font-size: 20px;
		font-weight: 700;
		line-height: 1.1;
	}
	&__form {
		margin-top: 20px;
	}
	& .sub-form-field {
		width: 100%;
		border: 1px solid #e3e3e3;
    padding: 11px 21px;
    margin-bottom: 10px;
		color: #a9a9a9;
		font-size: 18px;
		font-weight: 300;
		box-shadow: inset 0 -1px 15px rgba($color: #000000, $alpha: 0.1);
		&::placeholder {
			color: #a9a9a9;
		}
	}
	& .sub-form-btn {
		width: 100%;
		margin-top: 20px;
		padding: 8px 15px;
		border: none;
		color: #fff;
		background-color: #00a5ac;
		font-size: 18px;
		font-weight: 700;
		text-align: center;
	}
}